.bg-secondary {
    background: #f05323 !important;
}

.bg-warning {
    background: #fcbe4f !important;
}

.bg-info {
    background: #5c94c6 !important;
}

.btn-primary {
    background: #5a80fb !important;
}

.btn-provider {
    background: #5a80fb !important;
}

.btn-warning {
    color: #fff;
    background: #fcbe4f !important;
}

.text-info {
    color: #71a8d5 !important;
}

.text-warning {
    color: #fcbe4f !important;
}

.bg-dark {
    background: #3d3935 !important;
  }

.bg-light {
    background: #f8f9fa !important;
}
