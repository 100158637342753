.border-xywav {
    border-color: #f4364c !important;
}

.border-xyrem {
    border-color: #1e3d7d !important;
}

.bg-xywav {
    background: #f4364c !important;
}

.bg-xyrem {
    background: #1e3d7d !important;
}

.img-fluid-med {
    max-width: 100%;
    height: auto;
    max-height: 50px;
}
