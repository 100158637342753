// todo fix possitioning
.loader-workflow{
  position: fixed;
  top:0;
  width: 100%;
  
  div {
    background: #fff;
    padding: 30px 50px;
    border: 1px solid #000;
    border-radius: 1rem;
  }
}