.bg-rems {
    background: url('../../../assets/bg.png');
    min-height: 100vh;
}

$font-sizes: (
    sm: 0.875rem,
    md: 1rem,
    lg: 1.25rem,
    xl: 1.5rem,
    xxl: 2rem
);

.h1 {
    @media (max-width: 767px) {
        font-size: 1.5rem;
    }

    @media (min-width: 768px) {
        font-size: 2rem;
    }
}

.h2 {
    @media (max-width: 767px) {
        font-size: 1.25rem;
    }

    @media (min-width: 768px) {
        font-size: 1.5rem;
    }
}

.steps {
    @media (max-width: 767px) {
        font-size: 0.875rem;
    }

    @media (min-width: 768px) {
        font-size: 1rem;
    }
}

.icon {
    @media (max-width: 767px) {
        width: 32px;
        height: 32px;
    }

    @media (min-width: 768px) {
        width: 36px;
        height: 36px;
    }
}

.main-section-text {
    @media (max-width: 767px) {
        font-size: 1rem;
    }

    @media (min-width: 768px) {
        font-size: 1.125rem;
    }
}