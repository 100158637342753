.radio {
    margin-right: 10px;
    line-height: 40px;
    display: inline-block;
}

.radio-btn {
   margin-right: 6px;
    margin-top: 14px;
}
