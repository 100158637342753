.bg-dark-modal{
    color:#C8C8C8!important;
    background-color: #2D2D2D!important;
}

.btn-modal-close{
    padding: 0;
    background-color: #2D2D2D;
    border: 0;
    color: #fff;
    text-shadow: 2px 2px 5px #000,0 1px 0 #fff;
    font-size: 2.5rem;
    float: right;
    font-weight: 60;
    line-height: 1;
    color: #000;
    opacity: .5;
}

.btn-modal-close:hover{
    opacity: 0.7;
}
