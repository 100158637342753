.error-font{
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  
    .error-header{
      text-shadow: 1px 1px 2px rgba(29, 28, 28, 0.25);
  
    }
    
    .error-code{
      font-size: 5rem;
      background: -webkit-repeating-linear-gradient(-45deg, #3B71CA, #69a6ce, #3B71CA, #69a6ce, #3B71CA, #69a6ce, #3B71CA);
      letter-spacing: 0.3rem;
      margin: 0;
      padding: 0;
      outline: none;
      position: relative;
      bottom:0px;
      box-sizing: border-box;
      line-height: 1em;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 1px 1px 2px rgba(255,255,255,0.25);
    }

    .error-text{
      font-size: 2rem;
      letter-spacing: 0.2rem;
      transition: all 0.3s ease;
      color: #353434;
    }

    .error-button{
      font-size: 1.3rem;
      letter-spacing: 0.2rem;
      transition: all 0.3s ease;
      border:none;
    }
}
