.wrapper-textarea{
    position:relative; 
    overflow:auto;
    display:inline-block;
    width:100%;
    
  .second-heading {
    width:100%;
    text-align:left;
    font-weight:bold;
    float:left;
    opacity: 0.65;
    padding:3px;
  }

  .textarea-style{
    opacity: 1;
    width:100%;
    float:left;
    display:block;
    border:none;    
    outline:none;
  }
}

.wrapper-textarea:focus-within {
  box-shadow: 0 0 0 0.3rem rgba(134,183,254,0.6);
  box-shadow: 0 0 0 3px --moz-mac-focusring;
  border:1px solid #86B7FE;   
}
