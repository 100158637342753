.modal-bg-rems {
    background: url('../../../../assets/bg.png');
    min-height: 100%;
}

$font-sizes: (
    sm: 0.875rem,
    md: 1rem,
    lg: 1.25rem,
    xl: 1.5rem,
    xxl: 2rem
);

.modal-h1 {

    font-size: 1.5rem !important;
}

.modal-h2 {

    font-size: 1.25rem !important;

}

.modal-steps {

    font-size: 0.875rem;

}

.modal-icon {

    width: 32px;
    height: 32px;

}

.modal-main-section-text {

    font-size: 1rem;


}